import Link from "gatsby-link";
import React, { FC } from "react";

import { RouteComponentProps } from "@reach/router";

import { Button } from "../components/Button";
import { Layout } from "../components/Layout";
import { SEO } from "../components/Seo";
import { H1, Typography } from "../components/Typography";

const NotFoundPage: FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <Layout withBg>
      <SEO title="404: Not found" />
      <div className="flex min-h-full flex-col items-center justify-center mt-auto mb-0 sm:-mb-20">
        <H1>404 not found</H1>
        <Typography className="mt-4 text-center">
          The page you are looking for doesn&apos;t exist or an other error occured.
          <br />
          <br />
          Go back, or head to home to choose a new direction.
        </Typography>
        <Link to="/">
          <Button className="mt-8" style={{ minWidth: 190 }}>
            Home
          </Button>
        </Link>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
